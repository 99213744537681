<template>
    <AdminWrapper>
        <template v-slot:child>
            <template v-if="$store.state.Admin.loadingState"><Spinner /></template>

            <SubmitForm :is-open="isInvite" :close-modal="() => toggleSubmitModal(false)" />

            <div class="w-90 center pt5-l pt6 pb4 flex-l items-center justify-between">
                <h3 class="font-w2 pb2" style="color:#132C8C;">Partners Details</h3>
                <div>
                    <button class="btn3 mr3 mb0-l mb3" @click.prevent="toggleSubmitModal(true)">Invite Staff</button>
                    <!-- <button class="btn3 mr3 mb0-l mb3">Add User</button> -->
                    <button class="btn3 mr3 mb0-l mb3">Add business</button>
                    <button class="btn3 mb0-l mb3">Deactive</button>
                </div>
            </div>

            <!-- customer details, full name, phone-->
            <div class="w-90 center flex flex-wrap">
                <div class="w-25-l w-100 box-border pv4 tc">
                    <img :src="require('@/assets/images/wood-table.png')" alt="" />
                    <div class="pt3 b" style="color: #132C8C;">{{ getDetails.name }}</div>
                </div>

                <div class="w-25-l w-100 box-border pa4">
                    <div class=" pv2">
                        <div class="pb2" style="color: #55596EBF;">Name</div>
                        <div>{{ getDetails.name }}</div>
                    </div>
                    <!-- <div class=" pv2">
                        <div class="pb2" style="color: #55596EBF;">Email</div>
                        <div>-</div>
                    </div> -->
                    <div class=" pv2">
                        <div class="pb2" style="color: #55596EBF;">Phone number</div>
                        <div>{{ getDetails.phoneNumber }}</div>
                    </div>
                    <div class=" pv2">
                        <div class="pb2" style="color: #55596EBF;">Partner Discount</div>
                        <div>{{ getDetails.partnerDiscount }}%</div>
                    </div>
                    <div class=" pv2">
                        <div class="pb2" style="color: #55596EBF;">Customer Discount</div>
                        <div>{{ getDetails.customerDiscount }}%</div>
                    </div>
                </div>

                <div class="w-25-l w-100 box-border pa4">
                    <div class="pv2">
                        <div class="pb2" style="color: #55596EBF;">Discount Duration</div>
                        <div>{{ getDetails.duration }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596EBF;">Referral ID</div>
                        <div class="pb2" style="background: #F5F8FD; position: relative; padding:3px">
                            <div>{{ getDetails.referralCode || '-' }}</div>
                            <font-awesome-icon
                                @click="copyCode(getDetails.referralCode)"
                                v-if="!refferalCode"
                                icon="fa-copy"
                                style="position: absolute; right: 5px; top: 2px; curor: pointer"
                            />
                            <div v-if="refferalCode" style="position: absolute; right: 5px; top: 2px; font-size: 9px">
                                copied
                            </div>
                        </div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596EBF;">Referral Link</div>
                        <div class="pb2" style="background: #F5F8FD; position: relative; padding:3px">
                            <div>{{ getDetails.referralLink }}</div>
                            <font-awesome-icon
                                icon="fa-copy"
                                v-if="!refferalLink"
                                @click="copyCode(getDetails.referralLink)"
                                style="position: absolute; right: 5px; top: 2px; cursor: pointer"
                            />
                            <div v-else style="position: absolute; right: 5px; top: 2px; font-size: 9px">
                                copied
                            </div>
                        </div>

                        <!-- <button class="btn3">Copy</button> -->
                    </div>
                </div>

                <div class="w-25-l w-100 box-border pa4">
                    <div class="pv2">
                        <div class="pb2" style="color: #55596EBF;">Created date</div>
                        <div>{{ formatDate(getDetails.createdAt) }}</div>
                    </div>
                </div>
            </div>
            <!-- cards  -->
            <!-- Cards  -->
            <div class="w-90 center flex flex-wrap pv4">
                <!-- no of users-->
                <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text">
                    <div class="flex items-center pb3">
                        <img :src="require('@/assets/images/users-svg.svg')" alt="" />
                        <p class="pl2 font-w1">No of Accounts</p>
                    </div>
                    <div style="color: #132C8C;">
                        <h3 class="mb0">{{ $store.state.Admin?.dashboardUsers.count }}</h3>
                    </div>
                </div>
                <!-- no of users -->

                <!-- active users -->
                <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text">
                    <div class="flex items-center pb3">
                        <img :src="require('@/assets/images/users-svg.svg')" alt="" />
                        <p class="pl2 font-w1">Active Users</p>
                    </div>
                    <div style="color: #132C8C;">
                        <h3 class="mb0">{{ $store.state.Admin?.dashboardActiveUsers.count }}</h3>
                    </div>
                </div>
                <!-- active users -->
                <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text">
                    <div class="flex items-center pb3">
                        <img :src="require('@/assets/images/users-svg.svg')" alt="" />
                        <p class="pl2 font-w1">Transactions (count & value)</p>
                    </div>
                    <div style="color: #132C8C;">
                        <h3 class="mb0">{{ $store.state.Admin?.dashboardTransaction.count }}</h3>
                    </div>
                </div>
                <!-- active users -->
                <!-- active users -->
                <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text">
                    <div class="flex items-center pb3">
                        <img :src="require('@/assets/images/users-plan-admin.svg')" alt="" />
                        <p class="pl2 font-w1">Invoices</p>
                    </div>
                    <div style="color: #132C8C;">
                        <h3 class="mb0">{{ formatAmount($store.state.Admin?.dashboardInvoice.value) }}</h3>
                    </div>
                </div>
                <!-- active users -->
                <!-- active users -->
                <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text">
                    <div class="flex items-center pb3">
                        <img :src="require('@/assets/images/services-admin.svg')" alt="" />
                        <p class="pl2 font-w1">Paying Customer</p>
                    </div>
                    <div style="color: #132C8C;">
                        <h3 class="mb0">-</h3>
                    </div>
                </div>
                <!-- active users -->
            </div>
        </template>

        <!-- <div :class="isInvite && 'jquery-modal blocker current'" @click="onClickOutsideInviteRef">
            <div class="w-90 mv3 center" :class="!isInvite ? 'modal2' : 'modal-target2'">
                <form class="box-border relative" style="background: white" ref="inviteModalRef" @submit.prevent="handleInvite">
                    <router-link to="#" class="absolute top--1 right--2" @click.prevent="handleInviteModal(false, 'isInvite')">
                        <img src="../../../assets/images/close-icon-2.png" alt="" class="w-40 h-40" />
                    </router-link>
                    <div class="box-border-bottom f4 b pa3 pt4">Invite Team Member</div>
                    <div class="pa3 box-border-bottom">
                        <div class="flex flex-column pb3 items-start">
                            <label class="pb2 font-w1" for="inviteEmail">Email</label>
                            <input id="inviteEmail" type="text" placeholder="" class="box-border pa2 w-100" />
                        </div>
                    </div>
                    <div class="flex justify-between pa3">
                        <div class="db-l dn"></div>
                        <div>
                            <button class="btn2" @click.prevent="handleInviteModal(false, 'isInvite')">Cancel</button>
                            <button class="ml2" :disabled="state.disabledButton" type="submit">
                                {{ state.disabledButton ? 'Inviting, Please wait...' : 'Invite Member' }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div> -->
    </AdminWrapper>
</template>

<script>
import { ref } from '@vue/reactivity'
import AdminWrapper from '../../../layout/AdminWrapper.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed, onMounted } from '@vue/runtime-core'
import { formatDate, formatAmount } from '@/utils/lib'
import Spinner from '@/components/Spinner'
import SubmitForm from '@/components/invitePartner'
export default {
    components: {
        AdminWrapper,
        Spinner,
        SubmitForm
    },
    setup() {
        const getDetails = computed(() => store.state.Admin.getPartnerDetails)
        const store = useStore()
        const route = useRouter()
        const refferalLink = ref(false)
        const refferalCode = ref(false)
        const isInvite = ref(false)
        const inviteModalRef = ref(null)
        const disableSubmitButton = ref(false)

        const toggleSubmitModal = (modalState = false, e = null) => {
            // console.log(e)
            isInvite.value = modalState
            // invoiceReference.value = e?.reference
            disableSubmitButton.value = false
        }

        const getUserDetailsData = () => {
            const id = route.currentRoute.value.params.id;
            store.dispatch('Admin/getPartnerDetailsData', id)

            store.dispatch('Admin/getDahsboardUserData', { id })
            store.dispatch('Admin/getDahsboardActiveUserData', { id })
            store.dispatch('Admin/getDahsboardTransactionData', { id })
            store.dispatch('Admin/getDahsboardInvoiceData', { id })
        }

        onMounted(() => {
            getUserDetailsData()
        })

        const copyCode = async mytext => {
            try {
                await navigator.clipboard.writeText(mytext)
                if (mytext?.startsWith('http')) {
                    refferalLink.value = true
                    // console.log('link')
                } else {
                    refferalCode.value = true
                    // console.log('code')
                }
                // console.log('Copied')
            } catch ($e) {
                // console.log('Cannot copy', $e)
            }
        }

        return {
            getDetails,
            getUserDetailsData,
            formatDate,
            copyCode,
            refferalCode,
            refferalLink,
            isInvite,
            inviteModalRef,
            toggleSubmitModal,
            disableSubmitButton,
            formatAmount
        }
    }
}
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: #ffffff;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
}

.modal-header,
.modal-footer {
    padding: 15px;
    display: flex;
}

.modal-header {
    border-bottom: 1px solid #eeeeee;
    color: #4aae9b;
    justify-content: space-between;
}

.modal-footer {
    border-top: 1px solid #eeeeee;
    justify-content: flex-end;
}

.modal-body {
    position: relative;
    padding: 20px 10px;
}

.btn-close {
    border: none;
    font-size: 20px;
    padding: 20px;
    cursor: pointer;
    font-weight: bold;
    color: #4aae9b;
    background: transparent;
}

.modal-fade-enter,
.modal-fade-leave-active {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity 0.5s ease;
}
</style>
