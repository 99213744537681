<template>
    <div
        v-if="isOpen"
        tabindex="0"
        @keydown.esc="closeModal"
        class="w-100 center flex items-center"
        id="add-product-form"
        :class="isOpen && 'jquery-modal blocker current'"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-40-l w-90 pv2 center box-border" style="background: white" ref="submitFormModal">
            <div class="flex justify-between pa3">
                <div></div>
                <div>
                    <router-link to="#">
                        <img src="../assets/images/Close-icon.svg" alt="" @click.prevent="closeModal" />
                    </router-link>
                </div>
            </div>

            <form @submit.prevent="submit" class="entity-form tc">
                <div class="tc pa3 pa0-l">
                    <!-- <img src="@/assets/images/Delete-img.svg " style="width:50px;" alt="" /> -->
                    <h2 class="lh-title f3-l f5">Enter the email of the staff you wish to invite</h2>
                    <div class="" style="display:flex; justify-content: center;">
                        <div>
                            <input
                                type="email"
                                v-model="email"
                                name=""
                                class="input-style py2 inputInvite"
                                style="width"
                                placeholder="Enter email address"
                                required
                            />
                        </div>
                    </div>
                </div>
                <div class="pa3">
                    <div class="flex flex-wrap justify-end" style="gap: 1rem">
                        <button class="mt3" @click.prevent="closeModal">Close</button>
                        <button class="mt3 bg-dark-green" :disabled="$store.state.Admin.disabledButton">
                            Invite
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
    name: 'DeleteModal',
    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
        closeModal: Function
    },

    setup(props) {
        const submitFormModal = ref(null)
        const disableButton = ref(false)
        const email = ref('')
        const store = useStore()
        const route = useRouter()

        const submit = () => {
            disableButton.value = true

            store
                .dispatch('Admin/submitInviteStaff', {
                    id: route.currentRoute.value.params.id,
                    payload: {
                        email: email.value
                    }
                })
                .then(res => {
                    if (res === true) {
                        props.closeModal()
                        email.value = null
                    }
                })
            disableButton.value = false
        }

        const handleOnClickOutsideModal = e => {
            if (submitFormModal.value && !submitFormModal.value.contains(e.target)) {
                props.closeModal()
            }
        }

        return { submitFormModal, handleOnClickOutsideModal, email, submit, disableButton }
    }
}
</script>

<style scoped>
.inputInvite {
    width: 23rem;
}
</style>
